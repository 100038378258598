import React, { createContext, useState, useEffect, PropsWithChildren } from "react";
import { AnalyticsBrowser, Analytics, InitOptions } from "@segment/analytics-next";

import { segmentWriteKey as writeKey } from "src/constants";

export interface AnalyticsContextProps {
  analytics: Analytics | undefined;
  initialized: boolean;
}

let integrations: InitOptions["integrations"] = { All: false, "Segment.io": true };

if (process.env.NODE_ENV === "development") {
  integrations = { All: false };
}

export const AnalyticsContext = createContext<AnalyticsContextProps>({ analytics: undefined, initialized: false });

export const AnalyticsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!writeKey) {
      return;
    }
    AnalyticsBrowser.load({ writeKey }, { integrations })
      .then((response) => {
        const [_analytics] = response;

        setAnalytics(_analytics);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setInitialized(true);
      });
  }, []);

  return <AnalyticsContext.Provider value={{ analytics, initialized }}>{children}</AnalyticsContext.Provider>;
};
