import { keycloakOpenIdConnectAuthorizationEndpoint } from "src/constants";
import { getAuthRedirectUri } from "./getAuthRedirectUri";

export const getRedirectToIdpLogInUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const clientId = searchParams.get("client_id");
  const scope = searchParams.get("scope");
  const responseType = searchParams.get("response_type");
  const responseMode = searchParams.get("response_mode");
  const redirectUri = getAuthRedirectUri();
  const state = searchParams.get("state");
  const machineId = searchParams.get("machine_id");

  const forwardSearchParams = new URLSearchParams({
    client_id: clientId ?? "",
    scope: scope ?? "",
    response_type: responseType ?? "",
    response_mode: responseMode ?? "",
    redirect_uri: redirectUri ?? "",
    state: state ?? "",
    machine_id: machineId ?? "",
    telemetry_id: "login",
  });

  // the origin needs to be the same as authorization_endpoint in the openid-configuration
  return `${window.location.origin}${keycloakOpenIdConnectAuthorizationEndpoint}?${forwardSearchParams.toString()}`;
};
