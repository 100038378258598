import { LoadingIndicator } from "@k8slens/lds";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { sanitizeUrl } from "@braintree/sanitize-url";
import { useLocalStorage } from "usehooks-ts";
import { getRedirectAuthUri, isAuthorizedRedirectUri } from "src/services/getRedirectAuthToLensDesktopUrl";
import { legacyKeycloakIframeRedirectLocalStorageKey } from "src/constants";
import { useAnalytics } from "src/hooks/useAnalytics";
import { Context } from "@segment/analytics-next";

export function RedirectToLensDesktop() {
  const [lensDesktopRedirectUri] = useLocalStorage(legacyKeycloakIframeRedirectLocalStorageKey, "");
  const location = useLocation();
  const { initialized, trackRedirect } = useAnalytics();

  useEffect(() => {
    if (lensDesktopRedirectUri && initialized) {
      const redirectAuthUri = getRedirectAuthUri(location.search, lensDesktopRedirectUri);

      if (isAuthorizedRedirectUri(redirectAuthUri)) {
        const sanitizedRedirectAuthUri = sanitizeUrl(redirectAuthUri);

        let trackRedirectPromise: Promise<void | Context> | undefined;

        if (trackRedirect) {
          trackRedirectPromise = trackRedirect(sanitizedRedirectAuthUri);
        }

        if (trackRedirectPromise) {
          trackRedirectPromise.finally(() => {
            window.location.replace(sanitizedRedirectAuthUri);
          });
        } else {
          window.location.replace(sanitizedRedirectAuthUri);
        }
      }
    }
  }, [location.search, lensDesktopRedirectUri, trackRedirect, initialized]);

  return <LoadingIndicator size="2xl" />;
}
